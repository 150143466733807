"use client";
import {
  Button,
  Typography,
  Box,
  Divider,
  Menu,
  MenuItem,
  AppBar,
  Toolbar,
  Tabs,
  Tab,
  Link,
  useMediaQuery,
  IconButton,
  Drawer
} from "@mui/material";
import { Menu as MenuIcon, Twitter } from "@mui/icons-material";

import { AuthenticationContext, useAuth } from "../context/Authentication";
import React, { useContext, useEffect, useState } from "react";
import { BoxColumn } from "./common/Boxes";
import { shortenAddress } from "./common/util";
import { useRouter } from "next/router";
import LinkNext from "next/link";
import { Footer, SocialIcons } from "./Footer";
import { useIsUserAdminQuery } from "../generated/graphql_schema";
import { LinkButton } from "./common/LinkButton";



const tabsSignedIn = [
  {
    label: "collection",
    href: "/collection",
    index: 6
  },
  {
    label: "free mint",
    href: "/mint",
    index: 7
  }
];

const adminTabs = [
  {
    label: "admin",
    href: "/admin",
    index: 8
  }
];

const tabs = [
  {
    label: "what is runes?",
    href: "/whatisrunes",
    index: 0
  },
  {
    label: "tutorial",
    href: "/tutorial",
    index: 1
  },
  {
    label: "card packs",
    href: "/card-packs",
    index: 2
  },
  {
    label: "tournaments",
    href: "/tournaments",
    index: 3
  },
  {
    label: "leaderboard",
    href: "/leaderboard",
    index: 4
  },
  {
    label: "play",
    href: "/lobby",
    index: 5
  },
  {
    label: "ronin migration address",
    href: "/roninairdropaddress",
    index: 5
  }
];

const Header = () => {
  const { user, signOut } = useAuth();
  const isMobileView = useMediaQuery("(max-width:600px)");
  const router = useRouter();
  const [tab, setTab] = useState<number | false>(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { data } = useIsUserAdminQuery();
  const [userTabs, setUserTabs] = useState(tabs);

  useEffect(() => {
    // Update user-specific tabs
    let updatedTabs = [...tabs];
    if (user) updatedTabs = [...updatedTabs, ...tabsSignedIn];
    if (data?.isUserAdmin) updatedTabs = [...updatedTabs, ...adminTabs];
    setUserTabs(updatedTabs);
  }, [user, data]);

  useEffect(() => {
    // Ensure the selected tab matches the current route or falls back to the first tab
    const matchingTab = userTabs.find((t) => t.href === router.pathname);

    setTab(matchingTab?.index || userTabs[0]?.index || false);
  }, [router.pathname, userTabs]);

  const SignOutButton = (
    <Button
      color="secondary"
      onClick={signOut}
      sx={{ whiteSpace: "nowrap", fontSize: 14 }}
    >
      Sign Out
    </Button>
  );

  return (
    <AppBar position="static" sx={{ background: "transparent", boxShadow: "none" }}>
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: isMobileView ? "space-between" : "flex-start",
        }}
      >
        <Typography variant="h4" sx={{ mr: 1 }}>
          <LinkNext href="/">
            <FlameLink />
          </LinkNext>
        </Typography>

        {!isMobileView && (
          <>
            <Tabs
              value={tab}
              onChange={(_, newValue) => setTab(newValue)}
              sx={{
                flex: "1 1 300px",
                "& .MuiTabs-indicator": { backgroundColor: "white" },
              }}
            >
              {userTabs.map((t) => (
                <TabLink key={t.href} tab={t} />
              ))}
            </Tabs>
            {!user && router.pathname !== "/" && (
              <LinkButton href="/">Sign In</LinkButton>
            )}
          </>
        )}

        {user && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {user.ensName ? (
              <Typography sx={{ mr: 1 }}>{user.ensName}</Typography>
            ) : (
              <Typography sx={{ mr: 1 }}>
                {shortenAddress(user.publicAddress)}
              </Typography>
            )}
            {!isMobileView && SignOutButton}
          </Box>
        )}

        {isMobileView && (
          <>
            <IconButton onClick={() => setIsDrawerOpen(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="right"
              open={isDrawerOpen}
              onClose={() => setIsDrawerOpen(false)}
              PaperProps={{ sx: { backgroundColor: "#000" } }}
            >
              <BoxColumn>
                <Tabs
                  value={tab}
                  onChange={(_, newValue) => setTab(newValue)}
                  orientation="vertical"
                  sx={{
                    "& .MuiTabs-indicator": { backgroundColor: "white" },
                  }}
                >
                  {userTabs.map((t) => (
                    <TabLink key={t.href} tab={t} />
                  ))}
                  {user && (
                    <Tab
                      label="Sign Out"
                      value="signout"
                      onClick={signOut}
                      sx={{ color: "white" }}
                    />
                  )}
                </Tabs>
                <SocialIcons />
              </BoxColumn>
            </Drawer>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

// has Header and Footer
export const AppLayout = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter();
  const isInLobby = router.pathname.startsWith("/lobby/");
  const isInHome = router.pathname === "/";
  const isMobileView = useMediaQuery("(max-width:600px)");

  return (
    <BoxColumn
      sx={{
        width: "100%",
        height: "100%",
        justifyContent: isInHome && !isMobileView ? "space-between" : undefined
      }}
    >
      {!isInLobby && <Header />}
      {children}
      {isInHome && !isMobileView && <Footer />}
    </BoxColumn>
  );
};

// const LinkTab = ({
//   tab,
//   value,
// }: {
//   tab: { label: string; href: string };
//   value: string;
// }) => {
//   return (
//     <Link href={tab.href} passHref>
//       <Tab
//         key={tab.href}
//         value={tab.href} // Match href as value
//         label={tab.label}
//         sx={{
//           color: "white",
//           fontSize: "1rem",
//           textTransform: "uppercase",
//           marginRight: 2,
//           "&:hover": {
//             color: "orange", // Hover color
//           },
//           "&.Mui-selected": {
//             color: "orange", // Active tab color
//             fontWeight: "bold",
//           },
//         }}
//       />
//     </Link>
//   );
// };

const TabLink = ({ tab }: { tab: { label: string; href: string } }) => (
  <LinkNext href={tab.href} passHref legacyBehavior>
    <Tab
      component="a"
      label={tab.label}
      sx={{
        textTransform: "uppercase",
        color: "white",
        "&:hover": { color: "orange" },
        "&.Mui-selected": { color: "orange" },
      }}
    />
  </LinkNext>
);

import { useSpring, animated, config } from "react-spring";

const FlameLink = ({ className, style }: { className?: string; style?: React.CSSProperties }) => {
  const [props, set] = useSpring(() => ({
    backgroundPosition: "0% 0%",
    config: config.molasses
  }));
  const router = useRouter();

  useEffect(() => {
    const interval = setInterval(() => {
      set({
        backgroundPosition: `${Math.random() * 100}% 0%`
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [set]);

  const flameAnimation = {
    background: `linear-gradient(90deg, red, orange, yellow, orange, red)`,
    backgroundSize: "500% auto",
    WebkitBackgroundClip: "text",
    backgroundClip: "text",
    color: "transparent",
    textShadow: "2px 2px 4px rgba(255, 105, 0, 0.8)",
    cursor: "pointer",
    ...style, // Allow for additional inline styles
  };

  return (
    <animated.span style={{ ...flameAnimation, ...props }} className={className}>
      Runes
    </animated.span>
  );
};

export default FlameLink;
